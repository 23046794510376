import React from 'react';
import ReactDOM from 'react-dom';
// import {
// 	hydrate,
// 	render,
// } from 'react-dom';

// import { render } from 'react-snapshot';
import { BrowserRouter as Router } from 'react-router-dom';

import {
	// useLocation,
	// useHistory
} from "react-router-dom";
import 'index.css';
// import Layout from '../components/Layout';
import reportWebVitals from 'reportWebVitals';
import ReactGA from 'react-ga4';

// import { createUseStyles } from "react-jss";

import {
	ApolloClient,
	HttpLink,
	InMemoryCache,
	ApolloProvider,
	// useQuery,
	// gql
} from '@apollo/client';

import fetch from 'cross-fetch';

import Root from "Root";


const gqlUri = `${process.env.REACT_APP_BACKEND_URI}/graphql`;
// const gqlUri = `${process.env.REACT_APP_BACKEND_URI}/graphql`;
const client = new ApolloClient( {
	uri: gqlUri,
	cache: new InMemoryCache(),
	link: new HttpLink({ uri: gqlUri, fetch }),
} );

ReactGA.initialize('G-MYXTVW6PEF');

const appHeight = () => {
	const doc = document.documentElement;
	doc.style.setProperty(` --app-height`, `${window.innerHeight}px`)
}
window.addEventListener('resize', appHeight)
appHeight();


// console.log( "gqlUri:", gqlUri );

// const useStyles = createUseStyles( {
// 	root: {
// 		height: '100%',
// 	}
// } );

// const classes = useStyles();

// client
// 	.query( {
// 		query: gql`
// 			query {
// 				navItems(where: {
// 					nav: {
// 						slug: "bottomNav"
// 					}
// 				} ) {
// 					id
// 					order
// 					alt_name
// 					section {
// 					  name
// 					}
// 				}
// 			}
// 		`
// 	} )
// 	.then( result => console.log( result ) );

const rootElement = document.getElementById( 'root' );

ReactDOM.render(
// render (

// if ( rootElement.hasChildNodes() ) {
// 	hydrate(
// 		<React.StrictMode>
// 			<Router>
// 				<ApolloProvider client={client}>
// 					<Root />
// 				</ApolloProvider>
// 			</Router>
// 		</React.StrictMode>,
// 		rootElement );
// } else {
	// render(
		<>
			<Router>
				<ApolloProvider client={client}>
					<Root />
				</ApolloProvider>
			</Router>
		</>,
		rootElement );
// }
	// document.getElementById('root')

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
