import React, {
	useEffect,
	useState,
	useMemo,
} from "react";

import {
	useQuery,
} from "@apollo/client";

import { createUseStyles } from "react-jss";
import { Fade } from "@mui/material";

import {
	Link,
	useParams,
	useHistory,
} from "react-router-dom";

import {
	motion,
} from "framer-motion";

import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import "swiper/swiper.scss";
import "swiper/modules/pagination/pagination.scss";
import "swiper/modules/navigation/navigation.scss";
import SwiperCore, {
	Controller,
	Pagination,
	Navigation,
	Mousewheel,
} from "swiper";


import ConstantData from "../data/ConstantData";
import ThemeData from "../data/ThemeData";
import QueryData from "data/QueryData";

import imgFunctions from "util/imgFunctions";

import Map from "components/Map";
import Floorplans from "./Floorplans";
import HeaderComponents from "./HeaderComponents";
import HeaderContextData from "data/HeaderContextData";
import VirtualTour from "./VirtualTour";
import useOpenClosePane from "hooks/useOpenClosePane";
import ViewStudy from "./ViewStudy";
import SavedHomesSlideout from "./SavedHomesSlideout";


import contentPageX from "../img/content_page_x.svg";
import contentPageXDark from "../img/content_page_x_dark.svg";
import ButtonOutline from "./ButtonOutline";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import SwiperText from "./SwiperText";
import PhotoGallery from "react-photo-gallery";

SwiperCore.use( [Mousewheel, Pagination] );

const useStyles = createUseStyles(
	{
		wrapper: {
			height: "100%",
			color: ThemeData.colours.black,
		},
		contentPage: {
			display: "flex",
			flexDirection: "row",

			height: "calc( 100vh - var( --header-height ) )",
		},
		backButtonWrapper: {
			display: "flex",
			alignItems: "center",

		},
		backButton: {
			display: "flex",
			alignItems: "center",
			flex: "0 0 auto",
			cursor: "pointer",
			paddingLeft: 40,

			color: ThemeData.colours.primaryFont,
			fontSize: 14,
			"& > svg": {
				fontSize: 20,
				marginRight: 5
			}
		},
		contentPane: {
			width: 390,
			height: "calc( 100vh - var( --header-height ) )",

			flex: "0 0 auto",

			display: "flex",
			flexDirection: "column",

			background: ThemeData.colours.carvenGreen, 

			textAlign: "left",

			"&.withMap": {
				width: 550,
			},
		},
		contentContainer: {
			flex: "1 1 auto",
			height: "calc( 100vh - var( --header-height ) )",
			display: "flex",
			flexDirection: "column",
			boxSizing: "border-box",

			padding: 40,
		},
		navContainer: {
			height: 150,

			flex: "0 1 auto",

			padding: "0 40px 30px 40px",
		},
		navArrow: {
			marginTop: 30,
		},
		navArrowLeft: {

		},
		navArrowRight: {
			marginLeft: 20,
		},
		navArrowDisabled: {
			opacity: .1,
		},
		rightPane: {
			flex: "1 1 auto",
			width: "100%",
			height: "100%",

			background: "no-repeat center",
			backgroundSize: "cover",
			backgroundColor: 'rgba(0, 0, 0, 0.9)',
			objectFit: "contain",
		},
		swiperSlideImg: {
			width: 10,
		},
		rightPaneVideo: {
			display: "flex",
			objectFit: "contain",
		},
		rightPaneFullsized: {
			backgroundSize: "contain",
		},
		swiper: {
			"--swiper-theme-color": ThemeData.colours.primaryAccent,
		},
		contentArea: {
			flex: "0 0 auto",
		},
		sectionName: {
			flex: "0 0 auto",

			color: ThemeData.colours.primaryAccent,

			fontFamily: ThemeData.fonts.title,
			fontSize: 16,
		},
		title: {
			flex: "0 0 auto",

			color: ThemeData.colours.secondaryTitleFont,

			marginTop: ".5em",

			fontFamily: ThemeData.fonts.title,
			fontSize: "2.5em",
			letterSpacing: 0,
			lineHeight: "1.2em",
		},
		subTitle: {
			fontFamily: ThemeData.fonts.title,
			fontSize: "1.4em",
			letterSpacing: 0,
			lineHeight: "2.4em",
			color: ThemeData.colours.carvenWarmGrey
		},
		// keep left pane text box
		textBox: {
			minHeight: "25vh",
			maxHeight: "25vh",
			overflowY: "auto",
			...ThemeData.styles.thinScrollbar,
		},
		leftContentContainer: {
			display: "flex",
			flexDirection: "column",
		},
		content: {
			flex: "1 1 auto",
			// height: 'calc( 100vh - 297px )',

			color: ThemeData.colours.secondaryFont,

			fontFamily: ThemeData.fonts.copy,
			fontSize: "1.2em",
			letterSpacing: "0.0125em",
			lineHeight: "2em",

			// overflowY: "auto",

			...ThemeData.styles.thinScrollbar,
		},
		contentPageX: {
			position: "absolute",
			right: 0,

			width: 150,
			height: 150,

			background: "no-repeat center",
			backgroundImage: `url('${contentPageX}')`,

			filter: "drop-shadow(2px 2px 2px rgb(0, 0, 0, .15))",

			"&.isDark": {
				backgroundImage: `url('${contentPageXDark}')`,
			},
		},
		mapPane: {
			display: "none",

			"&.doShow": {
				display: "block",
			},
		},
		scrollIcon: {
			bottom: "30px !important",
			zIndex: 2,
		},
		floorplans: {
			display: "flex",
			flexDirection: "column",
			width: "100%"
		},
		activeButton: {
			backgroundColor: ThemeData.colours.carvenCoolGrey
		},
		sectionCategories: {
			marginTop: '20px',
			overflowY: 'auto',
    		height: '400px'
		},
		gallery: {
			overflowY: 'auto',
			backgroundColor: 'rgba( 0, 0, 0, .9 )'
		}
	},
	{
		name: "ContentPage",
	}
);
function ContentPage( { ...props } ) {
	const classes = useStyles();
	// const apiUri = NetData.apiUri;
	let { contentPageSlug } = useParams();
	if ( props.overrideUrlContentPageSlug )
		contentPageSlug = props.overrideUrlContentPageSlug;
	// const matchUri = useRouteMatch();
	const history = useHistory();

	const {
		toggleIsViewStudySlideUpOpen,
	} = useOpenClosePane( "ViewStudySlideUp" );


	const {
		isFloorplansViewStudySlideUpOpen,
		setIsFloorplansViewStudySlideUpOpenToClosed,
		toggleIsFloorplansViewStudySlideUpOpen,
	} = useOpenClosePane( "FloorplansViewStudySlideUp" );

	const {
		isFloorplansVirtualTourOpen,
		setIsFloorplansVirtualTourOpenToClosed,
		toggleIsFloorplansVirtualTourOpen,
	} = useOpenClosePane( "FloorplansVirtualTour" );

	const unitFcns = {
		selectedUnitId: props.selectedUnitId,
		setSelectedUnitId: props.setSelectedUnitId,

		isUnitDetailOpen: props.isUnitDetailOpen,
		setIsUnitDetailOpen: props.setIsUnitDetailOpen,
		toggleIsUnitDetailOpen: props.toggleIsUnitDetailOpen,
	};

	const [highlightCatPoi, setHighlightCatPoi] = useState( {
		isHighActive: false,
		highCatId: -1,
		highPoiId: -1,
	} );

	useEffect( () => {
	}, [highlightCatPoi] );


	const [procFlyTo] = useState( {
		doProcFlyTo: false,
		poiid: -1,
		catid: -1,
		coordinates: -1,
	} );

	const { loading, error, data } = useQuery( QueryData.contentPageQuery, {
		variables: {
			slug: contentPageSlug,
		}
	} );

	const viewStudyUrl = useQuery( QueryData.siteConfiguration);

	

	let doRenderInteractiveMap = false;
	let doOnlyRenderPhotos = false;

	const textMediaPairs = useMemo( () => {
		if ( data && data.contentPages[0].text_media_pairs.length > 0) {
			return data.contentPages[0].text_media_pairs;
		}
		return null;
	}, [data] );

	const doOnlyRenderSingleTextContent = useMemo( () => {
		if ( textMediaPairs && textMediaPairs.length > 0 ) {
			let areAllTMPTextsBlank = true;
			if ( textMediaPairs.length > 1 ) {
				for ( let i = 1; i < textMediaPairs.length; i++ ) {
					if ( textMediaPairs[i].text )
						areAllTMPTextsBlank = false;
				}
			}
			return areAllTMPTextsBlank;
		}
		return false;
	}, [textMediaPairs] );

	const renderContentPageMediaPane = ( contentPageMedia => {
		return (
			contentPageMedia && contentPageMedia.url.includes( ".mp4" ) ?
				<div
					className={`${classes.rightPane} ${classes.rightPaneVideo}`}
				>
					<video
						autoPlay
						muted
						loop
					>
						<source src={contentPageMedia.url} type="video/mp4" />
					</video>
				</div>
				:
				<div
					className={`${classes.rightPane} ${doOnlyRenderPhotos ? classes.rightPaneFullsized : ""}`}
					style={contentPageMedia && { backgroundImage: `url('${imgFunctions.GetImgBySizeUri( contentPageMedia, ConstantData.imgSizes.xlarge )}')` }}
				/>
		);
	} );

	const [currentSchemeMedia, setCurrentSchemeMedia] = useState( null );
	const [fadeMedia, setFadeMedia] = useState(false);
	
	const [activeButton, setActiveButton] = useState("");

	const [mediaSwiper, setMediaSwiper] = useState( null );
	const [textSwiper, setTextSwiper] = useState( null );

	let contentPage = data && data.contentPages[0];
	const backLocation = data && `/${contentPage.section.slug}`;

	doRenderInteractiveMap = data && contentPage.custom_function === ConstantData.contentPageCustomFunctions.interactiveMap;
	doOnlyRenderPhotos = data && contentPage.custom_function === ConstantData.contentPageCustomFunctions.photosOnly;

	const galleryPhotos = useMemo( () => {
		let galleryPhotos = [{}];

		if ( contentPage )
			galleryPhotos =
				[...contentPage.image]
					.sort( ( phA, phB ) => phA.order - phB.order )
					.map( (photo, index) => {
						return {
							id: index,
							key: index,
							src: imgFunctions.GetImgBySizeUri( photo, ConstantData.imgSizes.xlarge ),
							width: imgFunctions.GetImgBySizeWidth( photo, ConstantData.imgSizes.xlarge ),
							height: imgFunctions.GetImgBySizeHeight( photo, ConstantData.imgSizes.xlarge ),
						};
					} )

		return galleryPhotos;
	}, [data] );

	const customFunctionContent = useMemo( () => {		
		return {
			[ConstantData.contentPageCustomFunctions.craftPartners]: (
				<>
					<div className={classes.contentPane}>
						<div className={classes.contentContainer}>
							<h4 className={`${classes.contentArea} ${classes.sectionName}`}>
								{data && contentPage.section.name.toUpperCase()}
							</h4>
							<h2 className={`${classes.contentArea} ${classes.title}`}>
								{data && contentPage.title.toUpperCase()}
							</h2>
							<div
								className={`${classes.contentArea} ${classes.content}`}
							>
								<div>
									<div className={classes.textBox}>
										<ReactMarkdown rehypePlugins={[rehypeRaw]}>{currentSchemeMedia !== null ?currentSchemeMedia.text : ""}</ReactMarkdown>
									</div>
									<div className={classes.sectionCategories}>
										<h3 className={classes.subTitle}>ANDlight</h3>
										{textMediaPairs && textMediaPairs.slice(0, 4).map((image, idx) => (
											<span key={idx}>
												<ButtonOutline
													border={"0px"}
													alignItems={"left"}
													justifyContent={"left"}
													hoverBackgroundColour={ThemeData.colours.carvenWarmGrey}
													className={activeButton === image.colour ? `${classes.activeButton}` : ""}
													key={idx}
													onClick={() => getSelectedScheme(image.colour, idx)}
												>
													{image.colour}
												</ButtonOutline>
											</span>
										))}
										<h3 className={classes.subTitle}>Phantom Hands</h3>
										{textMediaPairs && textMediaPairs.slice(4, 6).map((image, idx) => (
											<span key={idx}>
												<ButtonOutline
													border={"0px"}
													alignItems={"left"}
													justifyContent={"left"}
													hoverBackgroundColour={ThemeData.colours.carvenWarmGrey}
													className={activeButton === image.colour ? `${classes.activeButton}` : ""}
													key={idx}
													onClick={() => getSelectedScheme(image.colour, idx)}
												>
													{image.colour}
												</ButtonOutline>
											</span>
										))}
										<h3 className={classes.subTitle}>Lock & Mortice</h3>
										{textMediaPairs && textMediaPairs.slice(6, 8).map((image, idx) => (
											<span key={idx}>
												<ButtonOutline
													border={"0px"}
													alignItems={"left"}
													justifyContent={"left"}
													hoverBackgroundColour={ThemeData.colours.carvenWarmGrey}
													className={activeButton === image.colour ? `${classes.activeButton}` : ""}
													key={idx}
													onClick={() => getSelectedScheme(image.colour, idx)}
												>
													{image.colour}
												</ButtonOutline>
											</span>
										))}
									</div>
								</div>
								
							</div>
						</div>
					</div>
					<Fade in={fadeMedia}>
					{renderContentPageMediaPane(currentSchemeMedia && currentSchemeMedia.media)}
					</Fade>
				</>
			),
			[ConstantData.contentPageCustomFunctions.homeAmenityFeatures]: (
				<>
					<div className={classes.contentPane}>
						<div className={classes.contentContainer}>
							<h4 className={`${classes.contentArea} ${classes.sectionName}`}>
								{data && contentPage.section.name.toUpperCase()}
							</h4>
							<h2 className={`${classes.contentArea} ${classes.title}`}>
								{data && contentPage.title.toUpperCase()}
							</h2>
							<div
								className={`${classes.contentArea} ${classes.content}`}
							>
								<div>
									<div className={classes.textBox}>
										<ReactMarkdown rehypePlugins={[rehypeRaw]}>{currentSchemeMedia !== null ?currentSchemeMedia.text : ""}</ReactMarkdown>
									</div>
									<div className={classes.sectionCategories}>
										<h3 className={classes.subTitle}>Interior</h3>
										{textMediaPairs && textMediaPairs.slice(0, 7).map((image, idx) => (
											<span key={idx}>
												<ButtonOutline
													border={"0px"}
													alignItems={"left"}
													justifyContent={"left"}
													hoverBackgroundColour={ThemeData.colours.carvenWarmGrey}
													className={activeButton === image.colour ? `${classes.activeButton}` : ""}
													key={idx}
													onClick={() => getSelectedScheme(image.colour, idx)}
												>
													{image.colour}
												</ButtonOutline>
											</span>
										))}
										<h3 className={classes.subTitle}>Amenities</h3>
										{textMediaPairs && textMediaPairs.slice(7, 11).map((image, idx) => (
											<span key={idx}>
												<ButtonOutline
													border={"0px"}
													alignItems={"left"}
													justifyContent={"left"}
													hoverBackgroundColour={ThemeData.colours.carvenWarmGrey}
													className={activeButton === image.colour ? `${classes.activeButton}` : ""}
													key={idx}
													onClick={() => getSelectedScheme(image.colour, idx)}
												>
													{image.colour}
												</ButtonOutline>
											</span>
										))}
										<h3 className={classes.subTitle}>Exterior</h3>
										{textMediaPairs && textMediaPairs.slice(11, 16).map((image, idx) => (
											<span key={idx}>
												<ButtonOutline
													border={"0px"}
													alignItems={"left"}
													justifyContent={"left"}
													hoverBackgroundColour={ThemeData.colours.carvenWarmGrey}
													className={activeButton === image.colour ? `${classes.activeButton}` : ""}
													key={idx}
													onClick={() => getSelectedScheme(image.colour, idx)}
												>
													{image.colour}
												</ButtonOutline>
											</span>
										))}
									</div>
								</div>
								
							</div>
						</div>
					</div>
					<Fade in={fadeMedia}>
					{renderContentPageMediaPane(currentSchemeMedia && currentSchemeMedia.media)}
					</Fade>
				</>
			),
			[ConstantData.contentPageCustomFunctions.floorplans]: (
				<div className={classes.floorplans}>
					<Floorplans
						{...unitFcns}
						isViewStudySlideUpOpen={isFloorplansViewStudySlideUpOpen}
						toggleIsViewStudySlideUpOpen={toggleIsFloorplansViewStudySlideUpOpen}
						isVirtualTourOpen={isFloorplansVirtualTourOpen}
						toggleIsVirtualTourOpen={toggleIsFloorplansVirtualTourOpen}
						viewUrl={viewStudyUrl.data && viewStudyUrl.data.siteConfiguration.view_study_iframe_url}
					/>
				</div>
			),
			[ConstantData.contentPageCustomFunctions.virtualTour]: (
				<VirtualTour
					isOpen={true}
					src={data ? data.contentPages.length > 0 && data.contentPages[0].custom_iframe_embed_url : ""}
					toggleVirtualTourFcn={() => history.goBack()}
				/>
			),
			[ConstantData.contentPageCustomFunctions.enjoyTheView]: (
				<ViewStudy
					isOpen={true}
					toggleViewStudyFcn={toggleIsViewStudySlideUpOpen}
					viewUrl={viewStudyUrl.data && viewStudyUrl.data.siteConfiguration.view_study_iframe_url}
					backLocation={props.sectionSlug}
				/>
			),
			[ConstantData.contentPageCustomFunctions.galleryContentPage]: (
				<div className={classes.gallery}>
					<PhotoGallery
						photos={galleryPhotos}
						direction='row'
						columns={3}
						margin={15}
					/>
				</div>
			)
		};
	}, [ConstantData, data, unitFcns] );

	// Pass selected colour in to function to set current scheme
	const getSelectedScheme = (colour) => {
		const scheme = textMediaPairs.find((image) => image.colour === colour);
		setActiveButton(colour);
		setFadeMedia(false);
		setTimeout(() => {
			setCurrentSchemeMedia(scheme);
			setFadeMedia(true);
		}, 100);
	};

	// useEffect for setting default media image on page load
	useEffect(() => {
		setFadeMedia(true);
		if (textMediaPairs && textMediaPairs.length > 0) {
			setCurrentSchemeMedia(textMediaPairs[0]);
		}
	}, [textMediaPairs]);

	if ( loading ) {
		return (
			<div>
				<p></p>
			</div>
		);
	}
	if ( error ) {
		return (
			<>
				<p>{console.log( "cpa", error.message )}</p>
			</>
		);
	}

	return (
		<>
			<motion.div
				className={classes.wrapper} id={contentPage.id}
				initial={{ y: "100vh" }}
				animate={{ y: 0 }}
				exit={{ y: "100vh" }}
				transition={{ type: "tween", duration: .5 }}
			>
				{props.doRenderHeader &&
					<HeaderComponents 
						headerContext={HeaderContextData.contentPageHeaderContext} 
						setIsUnitDetailOpen={props.setIsUnitDetailOpen} 
						toggleIsSavedHomesSlideoutOpen={props.toggleIsSavedHomesSlideoutOpen}
						toggleIsRegisterOpen={props.toggleIsRegisterOpen}
					>
						<div className={classes.backButtonWrapper}>
							{
								props.isUnitDetailOpen ? 
									<div
										className={classes.backButton}
										onClick={() => props.setIsUnitDetailOpen(false)}
									>
										<FontAwesomeIcon icon={faArrowLeft} /> BACK
									</div>
									:
									isFloorplansViewStudySlideUpOpen ?
										<div
											className={classes.backButton}
											onClick={() => setIsFloorplansViewStudySlideUpOpenToClosed()}
										>
											<FontAwesomeIcon icon={faArrowLeft} /> BACK
										</div>
										:
										isFloorplansVirtualTourOpen ?
											<div
												className={classes.backButton}
												onClick={() => setIsFloorplansVirtualTourOpenToClosed()}
											>
												<FontAwesomeIcon icon={faArrowLeft} /> BACK
											</div>
											:
											<Link
												to={backLocation}
											>
												<div
													className={classes.backButton}
												>
													<FontAwesomeIcon icon={faArrowLeft} /> BACK
												</div>
											</Link>
							}
						</div>
					</HeaderComponents>
				}
				<div
					className={classes.contentPage}
				>
					{contentPage.custom_function && !( doRenderInteractiveMap || doOnlyRenderPhotos ) ?
						customFunctionContent[contentPage.custom_function]
						:
						<>
							{!doOnlyRenderPhotos &&
								<><div className={`${classes.contentPane} ${doRenderInteractiveMap ? "withMap" : ""}`}>
									<div className={classes.contentContainer}>
										<h4 className={`${classes.contentArea} ${classes.sectionName}`}>
											{contentPage.section.name.toUpperCase()}
										</h4>
										<h2 className={`${classes.contentArea} ${classes.title}`}>
											{contentPage.title.toUpperCase()}
										</h2>
										<div
											className={`${classes.contentArea} ${classes.content}`}

										>
											{!doOnlyRenderSingleTextContent && textMediaPairs && textMediaPairs.length > 0 ?
												<SwiperText
													classes={classes}
													textMediaPairs={textMediaPairs}
													mediaSwiper={mediaSwiper}
													setTextSwiper={setTextSwiper}
												/>
												:
												<ReactMarkdown rehypePlugins={[rehypeRaw]}>{contentPage.content}</ReactMarkdown>
											}
										</div>
									</div>
								</div>
								</>
							}
							<div className={`${classes.rightPane} ${classes.mapPane} ${doRenderInteractiveMap ? "doShow" : ""}`} >
								<Map
									doShow={doRenderInteractiveMap}
									highlightCatPoi={highlightCatPoi}
									setHighlightCatPoi={setHighlightCatPoi}
									procFlyTo={procFlyTo}
								/>
							</div>
							{ doOnlyRenderSingleTextContent && textMediaPairs && textMediaPairs.length > 0 && currentSchemeMedia !== null ?
								<>
								<Swiper
									key={textMediaPairs.length}
									className={classes.swiper}
									modules={[Controller, Pagination, Navigation]}
									onSwiper={setMediaSwiper}
									controller={{ control: textSwiper }}
									slidesPerView = {1}
									navigation = {{ clickable: true }}
									mousewheel={true}
								>
									{textMediaPairs
										.map( ( tmp, idx ) => (
											<SwiperSlide
												className={classes.swiperSlideImg}
												key={idx}
											>
												{renderContentPageMediaPane( tmp.media )}
											</SwiperSlide>
										) )}
									{/* </div> */}
								
								</Swiper>
								
							</>
								:
								renderContentPageMediaPane( contentPage.image && contentPage.image[0] )
							}
						</>
					}
				</div>
			</motion.div>
			<SavedHomesSlideout
				isOpen={props.isSavedHomesSlideoutOpen}
				toggleIsOpen={() => props.toggleIsSavedHomesSlideoutOpen()}
				toggleIsSavedHomesCompareOpen={() => props.toggleIsSavedHomesCompareOpen()}
				toggleIsRegisterOpen={props.toggleIsSendToEmailOpen}
			/>
		</>
	);
}



ContentPage.defaultProps = {
	doRenderHeader: true,
};



export default ContentPage;