import { ConstantData } from './ConstantData';

export const filterableSources = {
	unit_types:{
		label: 'Unit Types',
		filterType: ConstantData.unitFilterTypes.checkedList,
		unitRelationDataType: 'unitHomeTypes',
		dataListItemLabelField: 'name'
	},
	floors: {
		label: 'Floors',
		filterType: ConstantData.unitFilterTypes.checkedList,
		unitRelationDataType: 'unitFloors',
		dataListItemLabelField: 'name'
	},
	// buildings: {
	// 	label: 'Building',
	// 	filterType: ConstantData.unitFilterTypes.checkedList,
	// 	unitRelationDataType: 'unitBuildings',
	// 	dataListItemLabelField: 'name',
	// },
	// price: {
	// 	label: 'Price',
	// 	filterType: ConstantData.unitFilterTypes.range,
	// 	unitField: 'price',
	// 	rangeMinimumValue: 1400,
	// 	rangeMaximumValue: 10000000,
	// 	rangeStep: 100000,
	// 	displayedDataFormattingFunction: ( value ) => {
	// 		return `$${value / 1000}K`
	// 	},
	// },
	size: {
		label: 'Size',
		filterType: ConstantData.unitFilterTypes.range,
		unitField: 'size',
		rangeMinimumValue: 643,
		rangeMaximumValue: 21743,
		rangeStep: 10,
		displayedDataFormattingFunction: ( value ) => {
			return `${value} sqft`
		},
	},
	// availability: {
	// 	label: 'Availability',
	// 	filterType: ConstantData.unitFilterTypes.checkedList,
	// 	unitRelationDataType: 'unitAvailabilityStates',
	// 	dataListItemLabelField: 'name',
	// },
	// baths: {
	// 	label: 'Baths',
	// 	filterType: ConstantData.unitFilterTypes.checkedList,
	// 	unitRelationDataType: 'baths',
	// 	dataListItemLabelField: 'name',
	// },
};

export const filterableSourcesHeaderButtons = [
    // {
    //     label: 'Availability',
    //     filterType: ConstantData.unitFilterTypes.checkedList,
    //     unitRelationDataType: 'unitAvailabilityStates',
    //     dataListItemLabelField: 'name',
    // },
    // {
    //     label: 'Price',
    //     filterType: ConstantData.unitFilterTypes.range,
    //     unitField: 'price',
    //     unitRelationDataType: "price",
    //     rangeMinimumValue: 1400,
    //     rangeMaximumValue: 10000000,
    //     rangeStep: 100000,
    //     displayedDataFormattingFunction: ( value ) => {
    //         return `$${value / 1000}K`
    //     },
    // },
	{
		label: 'Rooms',
		filterType: ConstantData.unitFilterTypes.checkedList,
		unitRelationDataType: 'unitHomeTypes',
		dataListItemLabelField: 'name',
	},
	{
		label: 'Floors',
		filterType: ConstantData.unitFilterTypes.checkedList,
		unitRelationDataType: 'unitFloors',
		dataListItemLabelField: 'name'
	},
    {
        label: 'Size',
        unitRelationDataType: "size",
        filterType: ConstantData.unitFilterTypes.range,
        unitField: 'size',
        rangeMinimumValue: 643,
        rangeMaximumValue: 21743,
        rangeStep: 10,
        displayedDataFormattingFunction: ( value ) => {
            return `${value} sqft`
        },
    },
	// {
	// 	label: 'Building',
	// 	filterType: ConstantData.unitFilterTypes.checkedList,
	// 	unitRelationDataType: 'unitBuildings',
	// 	dataListItemLabelField: 'name',
	// },
];