import {
	useState,
	useEffect,
	useRef,
	useMemo,
} from 'react';

import {
	useQuery,
	// gql
} from "@apollo/client";

import {
	Route,
	Switch,
	// Link,
	useRouteMatch,
	useParams,
} from "react-router-dom";

import {
	motion,
} from "framer-motion";

import Modal from '@mui/material/Modal';

import { createUseStyles } from "react-jss";



import Layout from "./Layout";
import ContentPage from "./ContentPage";
import ContentCard from "./ContentCard";
import Floorplans from "./Floorplans";
import Gallery from "./Gallery";



import ThemeData from "../data/ThemeData";
import SlugData from "../data/SlugData";
import ConstantData from "data/ConstantData";
import QueryData from "data/QueryData";


import { useHorizontalScroll } from "hooks/useHorizontalScroll";

import useOpenClosePane from "hooks/useOpenClosePane";
import useGlobalUnitsFilter from 'hooks/useGlobalUnitsFilter';


import overviewWatchVideoButton from "../img/overview_watch_video_button.svg";
import ContentPageCarousel from './ContentPageCarousel';

const useStyles = createUseStyles(
	{
		allWrapper: {
			height: '100%',
		},
		section: {
			height: '100%',

			display: 'flex',
			flexDirection: 'column',

			padding: '0 30px',

			boxSizing: 'border-box',

			fontFamily: ThemeData.fonts.titleSub,
			fontSize: '2em',
			letterSpacing: 0,

			overflowX: 'auto',

			...ThemeData.styles.thinScrollbar,
		},
		darkenSection: {
			backgroundColor: ThemeData.colours.transparentDarkening,
		},
		contentPage: {
			fontSize: 25,
		},
		marginSizer: {
			height: 30,

			flex: '0 1 auto',
		},
		marginSizerBottom: {
			height: 30,

			flex: '0 1 auto',
		},
		contentCardsContainer: {
			flex: '1 1 auto',

			display: 'flex',
			justifyContent: 'space-between',
		},
		contentCardContainer: {
			marginLeft: 20,

			'&.firstCardContainer': {
				marginLeft: 0,
			},
			'&.lastCardContainer': {
				paddingRight: 30,
			},
		},
		onlyImageCard: {
			flex: '0 0 auto !important',
		},
		oneUpContainer: {
			flex: '1 0 auto',
			width: '31%',

			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
		},
		twoUpHorizContainer: {
			flex: '0 1 auto',
		},
		twoUpVertContainer: {
			flex: '1 0 auto',
			width: '31%',
		},
		fourUpContainer: {
			flex: '1 0 auto',
			width: 'calc( 66% - 20px )',
		},
		sixUpContainer: {
		},
		overview: {
			marginLeft: 100,
			padding: 20,

			color: ThemeData.colours.primaryFontHighContrast,
			backgroundColor: ThemeData.colours.blackBg50,

			position: 'absolute',
			left: -20,
			bottom: 125,

			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-end',

			textAlign: 'left',
		},
		overviewHeader: {
			flex: '0 1 auto',

			width: 500,

			fontFamily: ThemeData.fonts.titleSub,
			'& h1': {
				margin: '20px auto',

				fontSize: '2.5em',
				fontWeight: 'normal',
				lineHeight: '.95em',
			}
		},
		overviewDescription: {
			flex: '0 1 auto',

			width: 500,

			marginBottom: 10,

			fontFamily: ThemeData.fonts.copy,
			fontSize: 16,
			lineHeight: '1.3em',
		},
		overviewWatchVideoButton: {
			flex: '0 1 auto',
			width: 500,
			height: 60,

			background: 'no-repeat',
			backgroundImage: `url(${overviewWatchVideoButton})`,

			cursor: 'pointer',
		},
		videoPopup: {
			width: '100%',
			height: '100%',
		},
		closeVideoButton: {
			position: 'absolute',
			top: 0,
			right: 0,
			zIndex: 1,

			padding: 20,

			cursor: 'pointer',

			color: ThemeData.colours.primaryFontHighestContrast,
			fontSize: 44,
			fontFamily: ThemeData.fonts.title,
		},
		mapboxOpen: {
			width: 25,
			height: 25,

			cursor: 'pointer',
		},
	},
	{ name: 'Section' },
);



function Section( { sectionSlug, ...props } ) {

	let { slug: slugParams } = useParams();

	const slug = useMemo( () => {
		let slugMemo = slugParams;

		if ( sectionSlug )
			slugMemo = sectionSlug;

		return slugMemo;
	}, [slugParams, sectionSlug] );


	const unitFcns = {
		selectedUnitId: props.selectedUnitId,
		setSelectedUnitId: props.setSelectedUnitId,

		isUnitDetailOpen: props.isUnitDetailOpen,
		setIsUnitDetailOpen: props.setIsUnitDetailOpen,
		toggleIsUnitDetailOpen: props.toggleIsUnitDetailOpen,
	}


	/** 
	 * Reset guf when navigating from page to page
	 */
	// const globalUnitsFilter = useGlobalUnitsFilter();
	const globalUnitsFilter = useGlobalUnitsFilter();
	useEffect( () => {
		globalUnitsFilter.resetFilter();
	}, [slug] )

	const classes = useStyles();
	const matchUri = useRouteMatch();


	const [horizontalOverflow, setHorizontalOverflow] = useState( true );
	const [tab, setTab] = useState(-1);

	

	const horizScrollSectionRef = useHorizontalScroll();


	const horizScrollElmRef = useRef( null );

	function isOverflown( element ) {
		if ( element ) {
			return element.scrollWidth > element.clientWidth;
		}
	}

	useEffect( () => {
		setHorizontalOverflow( !isOverflown( horizScrollElmRef.current ) );
	} );



	const {
		isVideoOpen,
		setIsVideoOpen,
	} = useOpenClosePane( "Video" );

	const videoRef = useRef();

	const handleClose = () => {
		setIsVideoOpen( false );
		videoRef.current.pause();
	}



	const resizeImgOnlyCards = () => {
		let imgCards = document.getElementsByClassName( 'Section-onlyImageCard-0-2-10' );
		for ( let imgCard of imgCards ) {
			let imgElm = imgCard.getElementsByTagName( 'img' )[0];
			if ( imgElm )
				imgCard.style.width = `${imgCard.offsetHeight * imgElm.naturalWidth / imgElm.naturalHeight}px`;
		};
	}

	useEffect( () => {
		window.addEventListener( "resize", resizeImgOnlyCards );
		return () => window.removeEventListener( "resize", resizeImgOnlyCards );
	}, [] )
	useEffect( () => {
		resizeImgOnlyCards();
	} );

	const { loading, error, data } = useQuery( QueryData.section, {
		variables: {
			slug,
		}
	} );

	const globalData = useQuery( QueryData.global);

	if ( slug === "404.html" ) return (
		<div>
			<h2>404 Error</h2>
			<p>The page you are trying to reach is not here.</p>
		</div>
	);

	if ( loading ) return <p></p>
	if ( error ) return <p>{console.log( 's', error.message )}</p>

	let firstOneUpContentPage;
	let isFirstCardContainer = true;
	let isLastCardContainer = false;

	const thisSection = data.sections[0];


	let doDarkenMiddleBG = false;
	if ( thisSection &&
		thisSection.custom_override_function &&
		thisSection.custom_override_function === ConstantData.sectionCustomFunctions.gallery ) {
		doDarkenMiddleBG = true;
	}

	return (
		<>
		<Switch>
			<Route path="404.html">
				<div>
					<h2>404 Error</h2>
					<p>The page you are trying to reach is not here.</p>
				</div>
			</Route>
			<Route exact path={matchUri.path}>
				<Layout 
					toggleIsSavedHomesSlideoutOpen={props.toggleIsSavedHomesSlideoutOpen} 
					isSavedHomesSlideoutOpen={props.isSavedHomesSlideoutOpen} 
					toggleIsSavedHomesCompareOpen={props.toggleIsSavedHomesCompareOpen} 
					isSavedHomesCompareOpen={props.isSavedHomesCompareOpen}
					toggleIsRegisterOpen={props.toggleIsRegisterOpen}
				>
					<section
						className={`${classes.section} ${doDarkenMiddleBG ? classes.darkenSection : ''}`}
						ref={horizScrollSectionRef}
					>
						{data && data.sections && thisSection && thisSection.custom_override_function &&
							thisSection.custom_override_function === ConstantData.sectionCustomFunctions.floorplans &&
							<>
								<Floorplans />
							</>
						}
						{data && data.sections && thisSection && thisSection.custom_override_function &&
							thisSection.custom_override_function === ConstantData.sectionCustomFunctions.gallery &&
							<>
								<Gallery
									doOverrideUseFullPhotoGallery={false}
									section={thisSection}
								/>
								{/* <AutoScroll32 className={classes.verticalScroll} /> */}
							</>
						}
						{
							data && data.sections && thisSection && !thisSection.custom_override_function &&
							<>
								<div className={classes.marginSizer} />
								{sectionSlug === SlugData.HOME_SLUG_NAME ?
									<>
										<motion.div
											className={classes.overview}
											initial={{opacity: 0 }}
											animate={{opacity: 1}}
											exit={{opacity: 0}}
											transition={{duration: 0.5}}
										>
											<div className={classes.overviewHeader}>
												<h1>{data.sections[0].content_pages[0].title}</h1>
											</div>
											<div className={classes.overviewDescription}>
												<p>{data.sections[0].content_pages[0].content}</p>
											</div>
											<div
												className={classes.overviewWatchVideoButton}
												hidden={!data.sections[0].content_pages[0].show_overview_video_button}
												onClick={() => setIsVideoOpen( true )}
											>
											</div>
										</motion.div>
										{!globalData.loading &&
											<Modal
												open={isVideoOpen}
												onClose={() => handleClose()}
											>
												<>
													<video
														className={classes.videoPopup}
														ref={videoRef}
														width={3840}
														height={2160}
														controls
														autoPlay
													>
														<source
															src={globalData.data.global.overviewVideo.url}
															type='video/mp4'
														/>
														{/* Your browser does not support video. */}
													</video>
													<div
														className={classes.closeVideoButton}
														onClick={() => handleClose()}
													>
														X
													</div>
												</>
											</Modal>
										}
									</>
									:
									<div
										className={classes.contentCardsContainer}
										ref={horizScrollElmRef}
									>
										{thisSection.content_pages &&
											[...thisSection.content_pages]
												.sort( ( cpA, cpB ) => cpA.order - cpB.order )
												.map( ( contentPage, index ) => {
													if ( index === thisSection.content_pages.length - 1 && !horizontalOverflow ) {
														isLastCardContainer = true
													}
													if ( contentPage.form && contentPage.form.value === "oneUp" ) {
														if ( !firstOneUpContentPage ) {
															firstOneUpContentPage = contentPage;
															return null;
														}
														return (
															<div
																className={
																	`${classes.contentCardContainer
																	} ${classes.oneUpContainer
																	} ${isFirstCardContainer ? "firstCardContainer" : ""
																	} ${isLastCardContainer ? "lastCardContainer" : ""
																	}`}
																key={contentPage.id}
																id={`content-card-${contentPage.id}`}
															>
																<ContentCard contentPage={firstOneUpContentPage} tab={tab} setTab={setTab} />
																<ContentCard contentPage={contentPage} tab={tab} setTab={setTab} />
																{firstOneUpContentPage = null}
																{isFirstCardContainer = false}
															</div>
														);
													}
													return (
														<div
															className={
																`${classes.contentCardContainer
																} ${classes[contentPage.form.value + "Container"]
																} ${isFirstCardContainer ? "firstCardContainer" : ""
																} ${isLastCardContainer ? "lastCardContainer" : ""
																} ${contentPage.is_only_image_card ? classes.onlyImageCard : ""
																}`}
															key={contentPage.id}
															id={`content-card-${contentPage.id}`}
														>
															<ContentCard contentPage={contentPage} key={contentPage.id} tab={tab} setTab={setTab}/>
															{isFirstCardContainer = false}
														</div>
													);
												} )
										}
									</div>
								}
								{
									sectionSlug === "neighbourhood" &&
									<div
										className={classes.mapboxOpen}
									>
										+
									</div>
								}
								<div className={classes.marginSizerBottom} />
							</>
						}
					</section>
				</Layout>
			</Route>
			<Route path={`${matchUri.path}/:contentPageSlug`} >
				<ContentPage
					{...unitFcns}
					contentPageSlugs={data}
					toggleIsSavedHomesSlideoutOpen={props.toggleIsSavedHomesSlideoutOpen} 
					isSavedHomesSlideoutOpen={props.isSavedHomesSlideoutOpen}
					toggleIsSavedHomesCompareOpen={props.toggleIsSavedHomesCompareOpen} 
					isSavedHomesCompareOpen={props.isSavedHomesCompareOpen}
					toggleIsRegisterOpen={props.toggleIsSendToEmailOpen}
					sectionSlug={sectionSlug}
				/>
			</Route>
		</Switch>
		<ContentPageCarousel 
			{...unitFcns} 
			sectionData={data.sections[0].content_pages.filter(page => page.custom_function !== 'photosOnly')} 
			tab={tab} 
			setTab={setTab} 
			toggleIsSavedHomesSlideoutOpen={props.toggleIsSavedHomesSlideoutOpen} 
			isSavedHomesSlideoutOpen={props.isSavedHomesSlideoutOpen}
			toggleIsSavedHomesCompareOpen={props.toggleIsSavedHomesCompareOpen} 
			isSavedHomesCompareOpen={props.isSavedHomesCompareOpen}
			toggleIsRegisterOpen={props.toggleIsSendToEmailOpen}
		/>
		</>
	);
}

export default Section;